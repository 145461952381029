<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-table-small">
              <span class="uk-text-bold">Jenis Kandang</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Daya Tampung</span>
            </th>
            <th class="uk-table-shrink">
              <span class="uk-text-bold">Tingkat</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Terakhir Diubah</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Diubah Oleh</span>
            </th>
            <th class="uk-table-shrink uk-text-center">
              <span class="uk-text-bold">Status</span>
            </th>
            <th class="uk-table-expand uk-text-center">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="cageCategorys.meta.total_count>0">
            <tr
              v-for="(cage, i) in cageCategorys.data"
              :key="i"
            >
              <td>{{ cage.name ? cage.name : '-' }}</td>
              <td>{{ cage.capacity ? cage.capacity : '-' }}</td>
              <td>{{ cage.floor ? cage.floor : '-' }}</td>
              <td>{{ formatDate(cage.updated_at) }}</td>
              <td>{{ cage.updated_by ? cage.updated_by : '-' }}</td>
              <td>
                <label-status :status="cage.status" />
              </td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`delete`, `Jenis Kandang`)"
                  v-lazy="`${images}/icon/trash.svg`"
                  alt=""
                  class="uk-margin-small-right img-click"
                  @click="showDeleteConfirmModal(cage)"
                > 
                <img
                  v-if="isCanAccessUser(`edit`, `Jenis Kandang`)"
                  v-lazy="`${images}/icon/edit-black.svg`"
                  alt=""
                  class="img-click"
                  @click="goToEdit(cage.id)"
                >
                <img
                  v-if="isCanAccessUser(`view`, `Jenis Kandang`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-left img-click"
                  @click="showDetail(cage.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="7"
          />
        </template>
        <template v-else>
          <loading-table :colspan="7" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="cageCategorys.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import LabelStatus from '@/components/globals/LabelStatus'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'
export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    LabelStatus
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      cageCategorys: 'cageCategory/cageCategorys',
      meta: 'cageCategory/meta'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getCageCategory(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
    await this.getCageCategory(this.meta)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getCageCategory: 'cageCategory/getCageCategory'
    }),
    ...mapMutations({
      setMeta: 'cageCategory/SET_META',
      setModalDelete: 'cageCategory/SET_MODAL_DELETE'
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDetail (id) {
      this.$router.push(`/admin/jenis-kandang/detail/${id}`)
    },
    goToEdit (id) {
      this.$router.push(`/admin/jenis-kandang/edit/${id}`)
    },
    showDeleteConfirmModal (cageCategory) {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(cageCategory)
    }
  }
}
</script>
